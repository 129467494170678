import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Alert, Divider, Result } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'

import { getOrder, acceptConditions } from 'Redux/actions/app-actions'

import Loader from 'Components/Loader'
import OrderCard from 'Components/OrderCard'
import Button from 'Components/Button'
import Condition from 'Components/Condition'
import { PageTitle, SmallTitle, Text } from 'Common/styled'

import { getQueryParam } from 'Common/utils'
import { device, colors, BU, ECOSIGN_STATUS_SEND_CONDITIONS_DONE } from 'Common/constants'

const { REACT_APP_API_URL } = process.env;

class Home extends Component {
  static propTypes = {
    acceptConditions: PropTypes.func,
    getOrder: PropTypes.func,
    order: PropTypes.object,
  }

  state = {
    isLoading: false,
    isConditionsAccepted: false,
    acceptedConditions: {}
  }

  componentDidMount() {
    this.getOrder()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isConditionsAccepted && !this.state.isConditionsAccepted) this.getOrder()
  }

  getOrder = async () => {
    const { getOrder } = this.props
    const uuid = getQueryParam('uuid')
    if (uuid) {
      this.setState({ isLoading: true })
      const res = await getOrder(uuid)
      const newState = { isLoading: false }
      if (res.status === 202) newState.hasError = true
      this.setState(newState)
    } else {
      this.setState({ hasError: true })
    }
  }

  changeConditions = changedValues => {
    this.setState(prevState => ({ acceptedConditions: { ...prevState.acceptedConditions, ...changedValues } }))
  }

  acceptConditions = async () => {
    const { order, acceptConditions } = this.props
    const conditionUrls = order.conditionIds.map(c =>
      `$REACT_APP_API_URL}/customer/order/${order.id}/condition/${c}?bu=${BU}`
    )
    this.setState({ isLoading: true })
    const res = await acceptConditions(order.id, conditionUrls)
    const newState = { isLoading: false }
    if (res.status === 200) newState.isConditionsAccepted = true
    this.setState(newState)
  }

  renderAcceptedConditions() {
    const { order } = this.props
    return <>
      <Text>
        <FormattedMessage
          id={'home.text.acceptedConditions'}
          values={{
            customer: <strong>{order.customerFirstName}</strong>,
            br: <br />,
          }}
        />
      </Text>
      <SmallTitle><FormattedMessage id={'home.title.otpCode'} /></SmallTitle>
      <PageTitle>{order.otp}</PageTitle>
      <Button
        icon={<ArrowLeftOutlined />}
        type={'link'}
        style={{ justifySelf: 'center', color: colors.grey4 }}
        onClick={() => this.setState({ isConditionsAccepted: false })}
      >
        <span>
          <FormattedMessage id={'home.button.back'} />
        </span>
      </Button>
    </>
  }

  renderConditions() {
    const { acceptedConditions } = this.state
    const { intl, order } = this.props
    const isSubmitEnabled = order.conditionIds?.every(c => acceptedConditions[c])
    const isConditionsEnabled =
      order.ecoSignStatus === ECOSIGN_STATUS_SEND_CONDITIONS_DONE
    return (
      <>
        <Divider style={{ margin: '8px 0' }} />
        {!isConditionsEnabled ?
          <Alert banner type={'success'} message={intl.formatMessage({ id: 'home.banner.conditionsAccepted' })} />
          : null}
        {order.conditionIds?.map(id => {
          const conditionUrl = `${REACT_APP_API_URL}/customer/order/${order.id}/condition/${id}?bu=${BU}`
          return (
            <Condition
              key={id}
              id={id}
              url={conditionUrl}
              isEnabled={isConditionsEnabled}
              onChange={this.changeConditions}
            />
          )
        })}
        {isConditionsEnabled ? (
          <Button
            disabled={!isSubmitEnabled}
            onClick={this.acceptConditions}
            style={{ justifySelf: 'end' }}>
            <FormattedMessage id={'home.button.acceptConditions'} />
          </Button>
        ) : null}
      </>
    )
  }

  render() {
    const { isLoading, hasError, isConditionsAccepted } = this.state
    const { order } = this.props
    if (isLoading) return <Loader />
    if (hasError)
      return (
        <Result
          status={404}
          title={<FormattedMessage id={'home.text.hasError'} />}
        />
      )
    if (isConditionsAccepted) return this.renderAcceptedConditions()
    return (
      <>
        <Text>
          <FormattedMessage
            id={'home.text.greeting'}
            values={{
              customer: <strong>{order.customerFirstName}</strong>,
              br: <br />,
            }}
          />
        </Text>
        <OrderCard data={order} device={device} />
        {this.renderConditions()}
      </>
    )
  }
}

const mapStateToProps = state => ({
  order: state.app.order,
})
const mapDispatchToProps = {
  getOrder,
  acceptConditions
}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Home))
